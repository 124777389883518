import React from "react"
import Layout from "../layouts/ru"
import Section01 from "./section01/index.ru"
import Intro from "./intro/index.ru"
import Contact from "./contact/index.ru"
import CookieConsent, { Cookies } from "react-cookie-consent";
import InfoDiv from '../components/InfoDiv';
import Section02 from "./gallery/index.ru"
import Section03 from "./b2b/index.ru"
import Section04 from "./swiece/index.ru"

const language = 3
const IndexPage = () => (
  <Layout>
  {/*
  <CookieConsent
      location="bottom"
      buttonText="Akceptuję"
      declineButtonText="Odrzucam"
      cookieName="gatsby-gdpr-google-analytics"
      style={{ background: "rgba(0,0,0,.8)" }}
      buttonStyle={{ background: "green", fontSize: "12px" }}
      declineButtonStyle={{background: "red",fontSize:"12px"}}
      expires={14}
      contentClasses="content"
      enableDeclineButton
  >
      Ten serwis używa plików cookies. Brak zmiany ustawień przeglądarki oznacza zgodę na ich użycie.{"  "}
      <InfoDiv element="pp"/>
  </CookieConsent>
  */}
  <div id={`starter_div`}>
    <Intro lang={language} />

    <Section01 lang={language}/>

    <Section02 lang={language} seoImg="Цветочный магазин Алма Вроцлав" />

    <Section03 lang={language} />

    <Section04 lang={language} />

    <Contact lang={language} />

  </div>
  </Layout>
)

export default IndexPage
